/**
 * Add functions for Facebook
 * */

(function (wpm, $, undefined) {

	let fBUserData

	wpm.loadFacebookPixel = () => {

		try {
			wpmDataLayer.pixels.facebook.loaded = true

			// @formatter:off
			!function(f,b,e,v,n,t,s)
			{if(f.fbq)return;n=f.fbq=function(){n.callMethod?
				n.callMethod.apply(n,arguments):n.queue.push(arguments)};
				if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
				n.queue=[];t=b.createElement(e);t.async=!0;
				t.src=v;s=b.getElementsByTagName(e)[0];
				s.parentNode.insertBefore(t,s)}(window, document,'script',
				'https://connect.facebook.net/en_US/fbevents.js');
			// @formatter:on

			// https://developers.facebook.com/docs/meta-pixel/advanced/advanced-matching

			let data = {}
			if (wpmDataLayer?.user?.id) data.external_id = wpmDataLayer.user.id
			if (wpmDataLayer?.order?.user_id) data.external_id = wpmDataLayer.order.user_id
			if (wpmDataLayer?.order?.billing_email_hashed) data.em = wpmDataLayer.order.billing_email_hashed
			if (wpmDataLayer?.order?.billing_first_name) data.fn = wpmDataLayer.order.billing_first_name
			if (wpmDataLayer?.order?.billing_last_name) data.ln = wpmDataLayer.order.billing_last_name
			if (wpmDataLayer?.order?.billing_phone) data.ph = wpmDataLayer.order.billing_phone
			if (wpmDataLayer?.order?.billing_city) data.ct = wpmDataLayer.order.billing_city
			if (wpmDataLayer?.order?.billing_state) data.st = wpmDataLayer.order.billing_state
			if (wpmDataLayer?.order?.billing_postcode) data.zp = wpmDataLayer.order.billing_postcode
			if (wpmDataLayer?.order?.billing_country) data.country = wpmDataLayer.order.billing_country

			fbq("init", wpmDataLayer.pixels.facebook.pixel_id, data)
			fbq("track", "PageView")

		} catch (e) {
			console.error(e)
		}
	}

	wpm.getRandomEventId = () => (Math.random() + 1).toString(36).substring(2)

	wpm.getFbUserData = () => {

		/**
		 * We need the first one for InitiateCheckout
		 * where getting the user_data from the browser is too slow
		 * using wpm.getCookie(), so we cache the user_data earlier.
		 * And we need the second one because the ViewContent hit happens too fast
		 * after adding a variation to the cart because the function to cache
		 * the user_data is too slow. But we can get the user_data using wpm.getCookie()
		 * because we don't move away from the page and can wait for the browser
		 * to get it.
		 */

		if (fBUserData) {
			return fBUserData
		} else {
			return wpm.getFbUserDataFromBrowser()
		}
	}

	wpm.setFbUserData = () => {
		fBUserData = wpm.getFbUserDataFromBrowser()
	}

	wpm.getFbUserDataFromBrowser = () => {

		let
			data = {}

		if (wpm.getCookie("_fbp") && wpm.isValidFbp(wpm.getCookie("_fbp"))) {
			data.fbp = wpm.getCookie("_fbp")
		}

		if (wpm.getCookie("_fbc") && wpm.isValidFbc(wpm.getCookie("_fbc"))) {
			data.fbp = wpm.getCookie("_fbc")
		}

		if (wpmDataLayer?.user?.id) {
			data.external_id = wpmDataLayer.user.id
		}

		if (navigator.userAgent) {
			data.client_user_agent = navigator.userAgent
		}

		return data
	}

	// https://developers.facebook.com/docs/marketing-api/conversions-api/parameters/fbp-and-fbc/
	wpm.isValidFbp = fbp => {

		let re = new RegExp(/^fb\.[0-2]\.\d{13}\.\d{8,20}$/);

		return re.test(fbp);
	}

	// https://developers.facebook.com/docs/marketing-api/conversions-api/parameters/fbp-and-fbc/
	wpm.isValidFbc = fbc => {

		let re = new RegExp(/^fb\.[0-2]\.\d{13}\.[\da-zA-Z]{8,50}$/);

		return re.test(fbc);
	}

	wpm.fbViewContent = product => {

		try {
			if (!wpmDataLayer?.pixels?.facebook?.loaded) return

			let eventId = wpm.getRandomEventId()

			fbq("track", "ViewContent", {
				content_type: "product",
				content_name: product.name,
				// content_category: product.category,
				content_ids: product.dyn_r_ids[wpmDataLayer.pixels.facebook.dynamic_remarketing.id_type],
				currency   : wpmDataLayer.shop.currency,
				value      : product.price,
			}, {
				eventID: eventId,
			})

			product["currency"] = wpmDataLayer.shop.currency

			jQuery(document).trigger("wpmFbCapiEvent", {
				event_name      : "ViewContent",
				event_id        : eventId,
				user_data       : wpm.getFbUserData(),
				event_source_url: window.location.href,
				custom_data     : wpm.fbGetProductDataForCapiEvent(product),
			})
		} catch (e) {
			console.error(e)
		}
	}

	wpm.fbGetProductDataForCapiEvent = product => {
		return {
			content_type: "product",
			content_ids : [
				product.dyn_r_ids[wpmDataLayer.pixels.facebook.dynamic_remarketing.id_type],
			],
			value       : product.quantity * product.price,
			currency    : wpmDataLayer.shop.currency,
		}
	}

	wpm.facebookContentIds = () => {
		let prodIds = []

		for (const [key, item] of Object.entries(wpmDataLayer.order.items)) {

			if (wpmDataLayer?.general?.variationsOutput && 0 !== item.variation_id) {
				prodIds.push(String(wpmDataLayer.products[item.variation_id].dyn_r_ids[wpmDataLayer.pixels.facebook.dynamic_remarketing.id_type]))
			} else {
				prodIds.push(String(wpmDataLayer.products[item.id].dyn_r_ids[wpmDataLayer.pixels.facebook.dynamic_remarketing.id_type]))
			}
		}

		return prodIds
	}

	wpm.trackCustomFacebookEvent = (eventName, customData = {}) => {
		try {
			if (!wpmDataLayer?.pixels?.facebook?.loaded) return

			let eventId = wpm.getRandomEventId()

			fbq("trackCustom", eventName, customData, {
				eventID: eventId,
			})

			jQuery(document).trigger("wpmFbCapiEvent", {
				event_name      : eventName,
				event_id        : eventId,
				user_data       : wpm.getFbUserData(),
				event_source_url: window.location.href,
				custom_data     : customData,
			})
		} catch (e) {
			console.error(e)
		}
	}

}(window.wpm = window.wpm || {}, jQuery));
